import { default as _91_46_46_46artist_id_93b68ZSmmozTMeta } from "/codebuild/output/src374861911/src/frontend/pages/artist/[...artist_id].vue?macro=true";
import { default as indexu5CqsTWhICMeta } from "/codebuild/output/src374861911/src/frontend/pages/artist/index.vue?macro=true";
import { default as _91video_slug_9365QBgB62FzMeta } from "/codebuild/output/src374861911/src/frontend/pages/auto/[video_slug].vue?macro=true";
import { default as indexFbZ6MKD6ZpMeta } from "/codebuild/output/src374861911/src/frontend/pages/auto/index.vue?macro=true";
import { default as _91artist_slug_93iyNRtu2FEtMeta } from "/codebuild/output/src374861911/src/frontend/pages/bio/[artist_slug].vue?macro=true";
import { default as _91video_slug_93nNpQLcd7J0Meta } from "/codebuild/output/src374861911/src/frontend/pages/browse/[video_slug].vue?macro=true";
import { default as indexlT3yrunS8BMeta } from "/codebuild/output/src374861911/src/frontend/pages/browse/index.vue?macro=true";
import { default as indexLuzV2Oi90BMeta } from "/codebuild/output/src374861911/src/frontend/pages/cancel/index.vue?macro=true";
import { default as _91categoryId_93VF4I8MTttHMeta } from "/codebuild/output/src374861911/src/frontend/pages/category/[categoryId].vue?macro=true";
import { default as codeauga1YNMutMeta } from "/codebuild/output/src374861911/src/frontend/pages/code.vue?macro=true";
import { default as _91id_93v0Qz8cYaGYMeta } from "/codebuild/output/src374861911/src/frontend/pages/collections/[id].vue?macro=true";
import { default as connectedP3FPd0UjMRMeta } from "/codebuild/output/src374861911/src/frontend/pages/collections/connected.vue?macro=true";
import { default as indexKuAVPYi0PHMeta } from "/codebuild/output/src374861911/src/frontend/pages/collections/index.vue?macro=true";
import { default as myRMOCZffEqRMeta } from "/codebuild/output/src374861911/src/frontend/pages/collections/my.vue?macro=true";
import { default as continue85D0VQdDeQMeta } from "/codebuild/output/src374861911/src/frontend/pages/continue.vue?macro=true";
import { default as new_45payment_45method0hLDLGP1V3Meta } from "/codebuild/output/src374861911/src/frontend/pages/failure/new-payment-method.vue?macro=true";
import { default as indexwjDm5skkoqMeta } from "/codebuild/output/src374861911/src/frontend/pages/ghost/index.vue?macro=true";
import { default as indexyAVUhdpSD5Meta } from "/codebuild/output/src374861911/src/frontend/pages/index.vue?macro=true";
import { default as loginPW3lahqspsMeta } from "/codebuild/output/src374861911/src/frontend/pages/login.vue?macro=true";
import { default as mobile_45app46kIagR2sHMeta } from "/codebuild/output/src374861911/src/frontend/pages/mobile-app.vue?macro=true";
import { default as indexQIOrKXnDQXMeta } from "/codebuild/output/src374861911/src/frontend/pages/my-account/index.vue?macro=true";
import { default as onboardingV39HqZ3NQvMeta } from "/codebuild/output/src374861911/src/frontend/pages/onboarding.vue?macro=true";
import { default as playlistQFY1VVJOnSMeta } from "/codebuild/output/src374861911/src/frontend/pages/playlist.vue?macro=true";
import { default as questionscpphbiB7mcMeta } from "/codebuild/output/src374861911/src/frontend/pages/questions.vue?macro=true";
import { default as quizOnboarding9ZALUz1cxKMeta } from "/codebuild/output/src374861911/src/frontend/pages/quizOnboarding.vue?macro=true";
import { default as _91token_93DiMOBHOCPuMeta } from "/codebuild/output/src374861911/src/frontend/pages/reset-password/[token].vue?macro=true";
import { default as indexQMcTv8V8BnMeta } from "/codebuild/output/src374861911/src/frontend/pages/reset-password/index.vue?macro=true";
import { default as index2aJwLKwCa7Meta } from "/codebuild/output/src374861911/src/frontend/pages/search/index.vue?macro=true";
import { default as selectSQFzTZfMNuMeta } from "/codebuild/output/src374861911/src/frontend/pages/select.vue?macro=true";
import { default as a_45_91artistSlug_93XdL0vaGekiMeta } from "/codebuild/output/src374861911/src/frontend/pages/share/a-[artistSlug].vue?macro=true";
import { default as c_45_91collectionId_93lBQ0HW72WTMeta } from "/codebuild/output/src374861911/src/frontend/pages/share/c-[collectionId].vue?macro=true";
import { default as v_45_91videoSlug_93DBQJOa4UqVMeta } from "/codebuild/output/src374861911/src/frontend/pages/share/v-[videoSlug].vue?macro=true";
import { default as indexq4KpHDmLf4Meta } from "/codebuild/output/src374861911/src/frontend/pages/signup/base-club/index.vue?macro=true";
import { default as planscLL9JpzsWdMeta } from "/codebuild/output/src374861911/src/frontend/pages/signup/base-club/plans.vue?macro=true";
import { default as indexPHQw7QB1xWMeta } from "/codebuild/output/src374861911/src/frontend/pages/signup/index.vue?macro=true";
import { default as plans8a1K8VpO18Meta } from "/codebuild/output/src374861911/src/frontend/pages/signup/plans.vue?macro=true";
import { default as indexI3k2KVxOmRMeta } from "/codebuild/output/src374861911/src/frontend/pages/signup/promo/index.vue?macro=true";
import { default as indexoiy9MWCrmoMeta } from "/codebuild/output/src374861911/src/frontend/pages/signup/select/index.vue?macro=true";
import { default as plans0u2Egkro4NMeta } from "/codebuild/output/src374861911/src/frontend/pages/signup/select/plans.vue?macro=true";
import { default as startsRj9agptk6Meta } from "/codebuild/output/src374861911/src/frontend/pages/start.vue?macro=true";
import { default as indexmpBz8YxlawMeta } from "/codebuild/output/src374861911/src/frontend/pages/success/index.vue?macro=true";
import { default as new_45payment_45methodbEl70sTfeXMeta } from "/codebuild/output/src374861911/src/frontend/pages/success/new-payment-method.vue?macro=true";
import { default as terms_of_useWhML685nd4Meta } from "/codebuild/output/src374861911/src/frontend/pages/terms_of_use.vue?macro=true";
export default [
  {
    name: _91_46_46_46artist_id_93b68ZSmmozTMeta?.name ?? "artist-artist_id",
    path: _91_46_46_46artist_id_93b68ZSmmozTMeta?.path ?? "/artist/:artist_id(.*)*",
    meta: _91_46_46_46artist_id_93b68ZSmmozTMeta || {},
    alias: _91_46_46_46artist_id_93b68ZSmmozTMeta?.alias || [],
    redirect: _91_46_46_46artist_id_93b68ZSmmozTMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/artist/[...artist_id].vue").then(m => m.default || m)
  },
  {
    name: indexu5CqsTWhICMeta?.name ?? "artist",
    path: indexu5CqsTWhICMeta?.path ?? "/artist",
    meta: indexu5CqsTWhICMeta || {},
    alias: indexu5CqsTWhICMeta?.alias || [],
    redirect: indexu5CqsTWhICMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/artist/index.vue").then(m => m.default || m)
  },
  {
    name: _91video_slug_9365QBgB62FzMeta?.name ?? "auto-video_slug",
    path: _91video_slug_9365QBgB62FzMeta?.path ?? "/auto/:video_slug()",
    meta: _91video_slug_9365QBgB62FzMeta || {},
    alias: _91video_slug_9365QBgB62FzMeta?.alias || [],
    redirect: _91video_slug_9365QBgB62FzMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/auto/[video_slug].vue").then(m => m.default || m)
  },
  {
    name: indexFbZ6MKD6ZpMeta?.name ?? "auto",
    path: indexFbZ6MKD6ZpMeta?.path ?? "/auto",
    meta: indexFbZ6MKD6ZpMeta || {},
    alias: indexFbZ6MKD6ZpMeta?.alias || [],
    redirect: indexFbZ6MKD6ZpMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/auto/index.vue").then(m => m.default || m)
  },
  {
    name: _91artist_slug_93iyNRtu2FEtMeta?.name ?? "bio-artist_slug",
    path: _91artist_slug_93iyNRtu2FEtMeta?.path ?? "/bio/:artist_slug()",
    meta: _91artist_slug_93iyNRtu2FEtMeta || {},
    alias: _91artist_slug_93iyNRtu2FEtMeta?.alias || [],
    redirect: _91artist_slug_93iyNRtu2FEtMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/bio/[artist_slug].vue").then(m => m.default || m)
  },
  {
    name: _91video_slug_93nNpQLcd7J0Meta?.name ?? "browse-video_slug",
    path: _91video_slug_93nNpQLcd7J0Meta?.path ?? "/browse/:video_slug()",
    meta: _91video_slug_93nNpQLcd7J0Meta || {},
    alias: _91video_slug_93nNpQLcd7J0Meta?.alias || [],
    redirect: _91video_slug_93nNpQLcd7J0Meta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/browse/[video_slug].vue").then(m => m.default || m)
  },
  {
    name: indexlT3yrunS8BMeta?.name ?? "browse",
    path: indexlT3yrunS8BMeta?.path ?? "/browse",
    meta: indexlT3yrunS8BMeta || {},
    alias: indexlT3yrunS8BMeta?.alias || [],
    redirect: indexlT3yrunS8BMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: indexLuzV2Oi90BMeta?.name ?? "cancel",
    path: indexLuzV2Oi90BMeta?.path ?? "/cancel",
    meta: indexLuzV2Oi90BMeta || {},
    alias: indexLuzV2Oi90BMeta?.alias || [],
    redirect: indexLuzV2Oi90BMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: _91categoryId_93VF4I8MTttHMeta?.name ?? "category-categoryId",
    path: _91categoryId_93VF4I8MTttHMeta?.path ?? "/category/:categoryId()",
    meta: _91categoryId_93VF4I8MTttHMeta || {},
    alias: _91categoryId_93VF4I8MTttHMeta?.alias || [],
    redirect: _91categoryId_93VF4I8MTttHMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/category/[categoryId].vue").then(m => m.default || m)
  },
  {
    name: codeauga1YNMutMeta?.name ?? "code",
    path: codeauga1YNMutMeta?.path ?? "/code",
    meta: codeauga1YNMutMeta || {},
    alias: codeauga1YNMutMeta?.alias || [],
    redirect: codeauga1YNMutMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/code.vue").then(m => m.default || m)
  },
  {
    name: _91id_93v0Qz8cYaGYMeta?.name ?? "collections-id",
    path: _91id_93v0Qz8cYaGYMeta?.path ?? "/collections/:id()",
    meta: _91id_93v0Qz8cYaGYMeta || {},
    alias: _91id_93v0Qz8cYaGYMeta?.alias || [],
    redirect: _91id_93v0Qz8cYaGYMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/collections/[id].vue").then(m => m.default || m)
  },
  {
    name: connectedP3FPd0UjMRMeta?.name ?? "collections-connected",
    path: connectedP3FPd0UjMRMeta?.path ?? "/collections/connected",
    meta: connectedP3FPd0UjMRMeta || {},
    alias: connectedP3FPd0UjMRMeta?.alias || [],
    redirect: connectedP3FPd0UjMRMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/collections/connected.vue").then(m => m.default || m)
  },
  {
    name: indexKuAVPYi0PHMeta?.name ?? "collections",
    path: indexKuAVPYi0PHMeta?.path ?? "/collections",
    meta: indexKuAVPYi0PHMeta || {},
    alias: indexKuAVPYi0PHMeta?.alias || [],
    redirect: indexKuAVPYi0PHMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: myRMOCZffEqRMeta?.name ?? "collections-my",
    path: myRMOCZffEqRMeta?.path ?? "/collections/my",
    meta: myRMOCZffEqRMeta || {},
    alias: myRMOCZffEqRMeta?.alias || [],
    redirect: myRMOCZffEqRMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/collections/my.vue").then(m => m.default || m)
  },
  {
    name: continue85D0VQdDeQMeta?.name ?? "continue",
    path: continue85D0VQdDeQMeta?.path ?? "/continue",
    meta: continue85D0VQdDeQMeta || {},
    alias: continue85D0VQdDeQMeta?.alias || [],
    redirect: continue85D0VQdDeQMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/continue.vue").then(m => m.default || m)
  },
  {
    name: new_45payment_45method0hLDLGP1V3Meta?.name ?? "failure-new-payment-method",
    path: new_45payment_45method0hLDLGP1V3Meta?.path ?? "/failure/new-payment-method",
    meta: new_45payment_45method0hLDLGP1V3Meta || {},
    alias: new_45payment_45method0hLDLGP1V3Meta?.alias || [],
    redirect: new_45payment_45method0hLDLGP1V3Meta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/failure/new-payment-method.vue").then(m => m.default || m)
  },
  {
    name: indexwjDm5skkoqMeta?.name ?? "ghost",
    path: indexwjDm5skkoqMeta?.path ?? "/ghost",
    meta: indexwjDm5skkoqMeta || {},
    alias: indexwjDm5skkoqMeta?.alias || [],
    redirect: indexwjDm5skkoqMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/ghost/index.vue").then(m => m.default || m)
  },
  {
    name: indexyAVUhdpSD5Meta?.name ?? "index",
    path: indexyAVUhdpSD5Meta?.path ?? "/",
    meta: indexyAVUhdpSD5Meta || {},
    alias: indexyAVUhdpSD5Meta?.alias || [],
    redirect: indexyAVUhdpSD5Meta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginPW3lahqspsMeta?.name ?? "login",
    path: loginPW3lahqspsMeta?.path ?? "/login",
    meta: loginPW3lahqspsMeta || {},
    alias: loginPW3lahqspsMeta?.alias || [],
    redirect: loginPW3lahqspsMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app46kIagR2sHMeta?.name ?? "mobile-app",
    path: mobile_45app46kIagR2sHMeta?.path ?? "/mobile-app",
    meta: mobile_45app46kIagR2sHMeta || {},
    alias: mobile_45app46kIagR2sHMeta?.alias || [],
    redirect: mobile_45app46kIagR2sHMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: indexQIOrKXnDQXMeta?.name ?? "my-account",
    path: indexQIOrKXnDQXMeta?.path ?? "/my-account",
    meta: indexQIOrKXnDQXMeta || {},
    alias: indexQIOrKXnDQXMeta?.alias || [],
    redirect: indexQIOrKXnDQXMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: onboardingV39HqZ3NQvMeta?.name ?? "onboarding",
    path: onboardingV39HqZ3NQvMeta?.path ?? "/onboarding",
    meta: onboardingV39HqZ3NQvMeta || {},
    alias: onboardingV39HqZ3NQvMeta?.alias || [],
    redirect: onboardingV39HqZ3NQvMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: playlistQFY1VVJOnSMeta?.name ?? "playlist",
    path: playlistQFY1VVJOnSMeta?.path ?? "/playlist",
    meta: playlistQFY1VVJOnSMeta || {},
    alias: playlistQFY1VVJOnSMeta?.alias || [],
    redirect: playlistQFY1VVJOnSMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/playlist.vue").then(m => m.default || m)
  },
  {
    name: questionscpphbiB7mcMeta?.name ?? "questions",
    path: questionscpphbiB7mcMeta?.path ?? "/questions",
    meta: questionscpphbiB7mcMeta || {},
    alias: questionscpphbiB7mcMeta?.alias || [],
    redirect: questionscpphbiB7mcMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/questions.vue").then(m => m.default || m)
  },
  {
    name: quizOnboarding9ZALUz1cxKMeta?.name ?? "quizOnboarding",
    path: quizOnboarding9ZALUz1cxKMeta?.path ?? "/quizOnboarding",
    meta: quizOnboarding9ZALUz1cxKMeta || {},
    alias: quizOnboarding9ZALUz1cxKMeta?.alias || [],
    redirect: quizOnboarding9ZALUz1cxKMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/quizOnboarding.vue").then(m => m.default || m)
  },
  {
    name: _91token_93DiMOBHOCPuMeta?.name ?? "reset-password-token",
    path: _91token_93DiMOBHOCPuMeta?.path ?? "/reset-password/:token()",
    meta: _91token_93DiMOBHOCPuMeta || {},
    alias: _91token_93DiMOBHOCPuMeta?.alias || [],
    redirect: _91token_93DiMOBHOCPuMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexQMcTv8V8BnMeta?.name ?? "reset-password",
    path: indexQMcTv8V8BnMeta?.path ?? "/reset-password",
    meta: indexQMcTv8V8BnMeta || {},
    alias: indexQMcTv8V8BnMeta?.alias || [],
    redirect: indexQMcTv8V8BnMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: index2aJwLKwCa7Meta?.name ?? "search",
    path: index2aJwLKwCa7Meta?.path ?? "/search",
    meta: index2aJwLKwCa7Meta || {},
    alias: index2aJwLKwCa7Meta?.alias || [],
    redirect: index2aJwLKwCa7Meta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: selectSQFzTZfMNuMeta?.name ?? "select",
    path: selectSQFzTZfMNuMeta?.path ?? "/select",
    meta: selectSQFzTZfMNuMeta || {},
    alias: selectSQFzTZfMNuMeta?.alias || [],
    redirect: selectSQFzTZfMNuMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/select.vue").then(m => m.default || m)
  },
  {
    name: a_45_91artistSlug_93XdL0vaGekiMeta?.name ?? "share-a-artistSlug",
    path: a_45_91artistSlug_93XdL0vaGekiMeta?.path ?? "/share/a-:artistSlug()",
    meta: a_45_91artistSlug_93XdL0vaGekiMeta || {},
    alias: a_45_91artistSlug_93XdL0vaGekiMeta?.alias || [],
    redirect: a_45_91artistSlug_93XdL0vaGekiMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/share/a-[artistSlug].vue").then(m => m.default || m)
  },
  {
    name: c_45_91collectionId_93lBQ0HW72WTMeta?.name ?? "share-c-collectionId",
    path: c_45_91collectionId_93lBQ0HW72WTMeta?.path ?? "/share/c-:collectionId()",
    meta: c_45_91collectionId_93lBQ0HW72WTMeta || {},
    alias: c_45_91collectionId_93lBQ0HW72WTMeta?.alias || [],
    redirect: c_45_91collectionId_93lBQ0HW72WTMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/share/c-[collectionId].vue").then(m => m.default || m)
  },
  {
    name: v_45_91videoSlug_93DBQJOa4UqVMeta?.name ?? "share-v-videoSlug",
    path: v_45_91videoSlug_93DBQJOa4UqVMeta?.path ?? "/share/v-:videoSlug()",
    meta: v_45_91videoSlug_93DBQJOa4UqVMeta || {},
    alias: v_45_91videoSlug_93DBQJOa4UqVMeta?.alias || [],
    redirect: v_45_91videoSlug_93DBQJOa4UqVMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/share/v-[videoSlug].vue").then(m => m.default || m)
  },
  {
    name: indexq4KpHDmLf4Meta?.name ?? "signup-base-club",
    path: indexq4KpHDmLf4Meta?.path ?? "/signup/base-club",
    meta: indexq4KpHDmLf4Meta || {},
    alias: indexq4KpHDmLf4Meta?.alias || [],
    redirect: indexq4KpHDmLf4Meta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/signup/base-club/index.vue").then(m => m.default || m)
  },
  {
    name: planscLL9JpzsWdMeta?.name ?? "signup-base-club-plans",
    path: planscLL9JpzsWdMeta?.path ?? "/signup/base-club/plans",
    meta: planscLL9JpzsWdMeta || {},
    alias: planscLL9JpzsWdMeta?.alias || [],
    redirect: planscLL9JpzsWdMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/signup/base-club/plans.vue").then(m => m.default || m)
  },
  {
    name: indexPHQw7QB1xWMeta?.name ?? "signup",
    path: indexPHQw7QB1xWMeta?.path ?? "/signup",
    meta: indexPHQw7QB1xWMeta || {},
    alias: indexPHQw7QB1xWMeta?.alias || [],
    redirect: indexPHQw7QB1xWMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: plans8a1K8VpO18Meta?.name ?? "signup-plans",
    path: plans8a1K8VpO18Meta?.path ?? "/signup/plans",
    meta: plans8a1K8VpO18Meta || {},
    alias: plans8a1K8VpO18Meta?.alias || [],
    redirect: plans8a1K8VpO18Meta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/signup/plans.vue").then(m => m.default || m)
  },
  {
    name: indexI3k2KVxOmRMeta?.name ?? "signup-promo",
    path: indexI3k2KVxOmRMeta?.path ?? "/signup/promo",
    meta: indexI3k2KVxOmRMeta || {},
    alias: indexI3k2KVxOmRMeta?.alias || [],
    redirect: indexI3k2KVxOmRMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/signup/promo/index.vue").then(m => m.default || m)
  },
  {
    name: indexoiy9MWCrmoMeta?.name ?? "signup-select",
    path: indexoiy9MWCrmoMeta?.path ?? "/signup/select",
    meta: indexoiy9MWCrmoMeta || {},
    alias: indexoiy9MWCrmoMeta?.alias || [],
    redirect: indexoiy9MWCrmoMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/signup/select/index.vue").then(m => m.default || m)
  },
  {
    name: plans0u2Egkro4NMeta?.name ?? "signup-select-plans",
    path: plans0u2Egkro4NMeta?.path ?? "/signup/select/plans",
    meta: plans0u2Egkro4NMeta || {},
    alias: plans0u2Egkro4NMeta?.alias || [],
    redirect: plans0u2Egkro4NMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/signup/select/plans.vue").then(m => m.default || m)
  },
  {
    name: startsRj9agptk6Meta?.name ?? "start",
    path: startsRj9agptk6Meta?.path ?? "/start",
    meta: startsRj9agptk6Meta || {},
    alias: startsRj9agptk6Meta?.alias || [],
    redirect: startsRj9agptk6Meta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/start.vue").then(m => m.default || m)
  },
  {
    name: indexmpBz8YxlawMeta?.name ?? "success",
    path: indexmpBz8YxlawMeta?.path ?? "/success",
    meta: indexmpBz8YxlawMeta || {},
    alias: indexmpBz8YxlawMeta?.alias || [],
    redirect: indexmpBz8YxlawMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/success/index.vue").then(m => m.default || m)
  },
  {
    name: new_45payment_45methodbEl70sTfeXMeta?.name ?? "success-new-payment-method",
    path: new_45payment_45methodbEl70sTfeXMeta?.path ?? "/success/new-payment-method",
    meta: new_45payment_45methodbEl70sTfeXMeta || {},
    alias: new_45payment_45methodbEl70sTfeXMeta?.alias || [],
    redirect: new_45payment_45methodbEl70sTfeXMeta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/success/new-payment-method.vue").then(m => m.default || m)
  },
  {
    name: terms_of_useWhML685nd4Meta?.name ?? "terms_of_use",
    path: terms_of_useWhML685nd4Meta?.path ?? "/terms_of_use",
    meta: terms_of_useWhML685nd4Meta || {},
    alias: terms_of_useWhML685nd4Meta?.alias || [],
    redirect: terms_of_useWhML685nd4Meta?.redirect,
    component: () => import("/codebuild/output/src374861911/src/frontend/pages/terms_of_use.vue").then(m => m.default || m)
  }
]